import React from 'react'
import MainBanner from './MainBanner'
import MainPageContent from './MainPageContent'

const MainPage = (params) => {
    return <>
      <MainBanner/>
      {/* <MainPageContent/> */}
    </>
}

export default MainPage;
